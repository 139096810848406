<template>
    <div
        v-if="!eleDestroyed"
        class="relative w-full h-full flex items-center justify-center"
    >
        <canvas
            ref="canvasEle"
            :width="canvasWidth"
            :height="canvasHeight"
            class="w-full h-full absolute blur-2xl"
        />
        <button
            v-if="muteButtonVisible && videoLoaded && play"
            class="absolute right-2 z-30 rounded-full bg-gray-600 bottom-3 p-1.5 cursor-pointer"
            @click.prevent.stop="toggleMute"
        >
            <SpeakerWaveIcon
                v-if="!muted"
                class="h-5 w-5 text-white"
                aria-hidden="true"
            />
            <SpeakerXMarkIcon
                v-else
                class="h-5 w-5 text-white"
                aria-hidden="true"
            />
        </button>
        <!--        <div :class="aspectRatio" class="max-h-full max-w-full"> -->
        <div :class="aspectRatio" class="h-full">
            <div ref="placeholderEle" />
        </div>
        <!--        <PlayerProgressBar :percent="30" /> -->
    </div>
</template>

<script lang="ts" setup>
import '@mux/mux-player'
import type { PropType } from 'vue'
import { SpeakerWaveIcon, SpeakerXMarkIcon } from '@heroicons/vue/20/solid'
import type { VehicleAsset } from '~/utils/types/inventory'
// import PlayerProgressBar from '~/components/Player/PlayerProgressBar.vue'

const globalStore = useGlobalStore()

const props = defineProps({
    videoId: {
        type: String,
        required: true,
    },
    initialized: {
        type: Boolean,
        default: true,
    },
    asset: {
        type: Object as PropType<VehicleAsset | null>,
        default: null,
    },
    aspectRatio: {
        type: String,
        default: null,
    },
    muted: {
        type: Boolean,
        default: true,
    },
    muteButtonVisible: {
        type: Boolean,
        default: true,
    },
    poster: {
        type: String,
        default: null,
    },
    placeholder: {
        type: String,
        default: null,
    },
    controls: {
        type: Boolean,
        default: true,
    },
    play: {
        type: Boolean,
        default: false,
    },
    title: {
        type: String,
        default: null,
    },
    showCaptions: {
        type: Boolean,
        default: false,
    },
    loop: {
        type: Boolean,
        default: false,
    },
    startTime: {
        type: [Number, String] as PropType<'end' | 'middle' | number | null>,
        default: null,
    },
})

const videoLoaded = ref<boolean>(false)
const placeholderEle = ref()
const canvasEle = ref<HTMLCanvasElement>()
const ambientCtx = ref<CanvasRenderingContext2D>()
const canvasWidth = ref(320)
const canvasHeight = ref(320)
const eleDestroyed = ref(false)
const $player = ref()

const emit = defineEmits(['ended', 'loaded', 'muted'])

const nativeVideo = computed(() => $player.value?.media.nativeEl)

onMounted(() => {
    if (props.initialized) {
        initialize()
    }
})

onBeforeUnmount(() => {
    // prevents video from playing on route change if not paused
    $player.value = undefined
})

watch(
    () => props.initialized,
    (initialized) => {
        console.log(initialized)
        if (initialized) {
            initialize()
        }
    },
)

watch(
    () => props.play,
    (playing) => {
        if (playing) {
            try {
                nativeVideo.value.play()
            } catch (e: any) {}

            showLog(`Video Playing: ${nativeVideo.value.currentTime}`)
        } else {
            try {
                nativeVideo.value.pause()
            } catch (e: any) {}

            showLog(`Video Paused: ${nativeVideo.value.currentTime}`)
        }
    },
)

watch(
    () => props.muted,
    (muted) => {
        if (muted) {
            try {
                $player.value.muted = true
            } catch (e: any) {}

            showLog(`Video Muted: ${nativeVideo.value.currentTime}`)
        } else {
            try {
                $player.value.muted = false
            } catch (e: any) {}

            showLog(`Video Unmuted: ${nativeVideo.value.currentTime}`)
        }
    },
)

const customStyles = computed<string[]>(() => {
    const returnArr = []

    if (!props.controls) {
        returnArr.push('--controls: none')
    }

    return returnArr
})

const aspectRatio = computed(() => {
    switch (props.aspectRatio ?? props.asset?.mux_data?.aspect_ratio) {
        case '9:16':
            return 'aspect-portrait'
        case '4:3':
            return 'aspect-4/3'
        case '1:1':
            return 'aspect-square'
        case '16:9':
            return 'aspect-video'
        default:
            return 'aspect-4/3'
    }
})

function initialize() {
    $player.value = document.createElement('mux-player')
    $player.value.setAttribute('playback-id', props.videoId)

    // aspect-video, aspect-portrait, aspect-square, aspect-4/3
    $player.value.setAttribute(
        'class',
        'max-h-full max-w-full ' + aspectRatio.value,
    )

    if (props.muted) {
        $player.value.setAttribute('muted', 'true')
    }

    if (props.loop) {
        $player.value.setAttribute('loop', 'true')
    }

    if (!props.showCaptions) {
        $player.value.setAttribute('default-hidden-captions', 'true')
    }

    if (props.poster) {
        $player.value.setAttribute('poster', props.poster)
    }

    if (props.placeholder) {
        $player.value.setAttribute('placeholder', props.placeholder)
    }

    if (props.title) {
        $player.value.setAttribute('metadata-video-title', props.title)
    }

    if (customStyles.value.length > 0) {
        $player.value.setAttribute('style', customStyles.value.join(';'))
    }

    placeholderEle.value?.replaceWith($player.value)

    addEventListeners()
    initializeCanvas()
}

function initializeCanvas() {
    if (canvasEle.value) {
        ambientCtx.value = canvasEle.value.getContext('2d') ?? undefined

        const posterEle = document.createElement('img')
        posterEle.src = $player.value.poster

        nativeVideo.value?.addEventListener('loadedmetadata', function () {
            if (nativeVideo.value) {
                canvasWidth.value = nativeVideo.value.videoWidth / 4
                canvasHeight.value = nativeVideo.value.videoHeight / 4

                if (ambientCtx.value) {
                    ambientCtx.value.canvas.width = canvasWidth.value
                    ambientCtx.value.canvas.height = canvasHeight.value
                    ambientCtx.value.drawImage(
                        posterEle,
                        0,
                        0,
                        canvasWidth.value,
                        canvasHeight.value,
                    )
                }
            }
        })

        nativeVideo.value?.addEventListener('play', paintCanvas, false)
        ambientCtx.value?.drawImage(
            posterEle,
            0,
            0,
            canvasWidth.value,
            canvasHeight.value,
        )
    }
}

function addEventListeners() {
    nativeVideo.value?.addEventListener('ended', () => {
        endedEvents()
        showLog('Video ended.')
    })
    nativeVideo.value?.addEventListener('canplay', () => loadedEvents())
}

function paintCanvas() {
    if (nativeVideo.value) {
        if (nativeVideo.value.paused || nativeVideo.value.ended) {
            return
        }

        ambientCtx.value?.drawImage(
            nativeVideo.value,
            0,
            0,
            canvasWidth.value,
            canvasHeight.value,
        )
        nativeVideo.value.requestVideoFrameCallback(paintCanvas)
    }
}

function endedEvents() {
    emit('ended')
}

function loadedEvents() {
    if (nativeVideo.value) {
        if (props.play) {
            nativeVideo.value.play()
        }

        if (props.startTime !== null) {
            const duration = nativeVideo.value.duration

            if (typeof props.startTime === 'number') {
                if (props.startTime <= duration) {
                    nativeVideo.value.currentTime = props.startTime
                }
            } else if (props.startTime === 'end') {
                nativeVideo.value.currentTime = duration
            } else if (props.startTime === 'middle') {
                nativeVideo.value.currentTime = duration / 2
            }
        }

        videoLoaded.value = true

        emit('loaded')
    }
}

function showLog(text: string) {
    if (globalStore.videoLogging) {
        console.log(
            '========================================================================',
        )
        console.log(`Asset ID: ${props.videoId}`)
        console.log(text)
        console.log(
            '========================================================================',
        )
    }
}

function toggleMute() {
    emit('muted', !props.muted)
}
</script>

<style scoped>
mux-player {
    aspect-ratio: 9 / 16;
}
</style>
